<template>
  <div>
    <div class="create-button mb-3">
      <b-button variant="primary" v-b-modal.modal-lg.register-user>{{
        $t("administration.register_new_user.button_title")
      }}</b-button>
      <b-modal
        id="register-user"
        :title="$t('administration.register_new_user.modal_title')"
        @show="resetModal"
        @hidden="resetModal"
        @ok="registerUser"
      >
        <div class="container">
          <div class="row">
            <div class="mt-3 mr-5">
              {{ $t("administration.register_new_user.username_field") }}
            </div>
            <div class="">
              <b-form-group
                id="b-form-group-username"
                :invalid-feedback="invalidUsername"
                :state="usernameState"
              >
                <b-form-input
                  id="b-form-input-username"
                  v-model="username"
                  :state="usernameState"
                  :placeholder="
                    $t('administration.register_new_user.username_placeholder')
                  "
                  type="text"
                  trim
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="mt-3 mr-5">
              {{ $t("administration.register_new_user.firstname_field") }}
            </div>
            <div class="">
              <b-form-group
                id="b-form-group-first-name"
                :invalid-feedback="invalidFirstName"
                :state="firstNameState"
              >
                <b-form-input
                  id="b-form-input-first-name"
                  v-model="firstName"
                  :state="firstNameState"
                  :placeholder="
                    $t('administration.register_new_user.firstname_placeholder')
                  "
                  type="text"
                  trim
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="mt-3 mr-5">
              {{ $t("administration.register_new_user.lastname_field") }}
            </div>
            <div class="">
              <b-form-group
                id="b-form-group-last-name"
                :invalid-feedback="invalidLastName"
                :state="lastNameState"
              >
                <b-form-input
                  id="b-form-input-last-name"
                  v-model="lastName"
                  :state="lastNameState"
                  :placeholder="
                    $t('administration.register_new_user.lastname_placeholder')
                  "
                  type="text"
                  trim
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="mt-3 mr-13">
              {{ $t("administration.register_new_user.email_field") }}
            </div>
            <div class="">
              <b-form-group
                id="b-form-group-email"
                :invalid-feedback="invalidEmail"
                :state="emailState"
              >
                <b-form-input
                  id="b-form-input-email"
                  v-model="email"
                  :state="emailState"
                  :placeholder="
                    $t('administration.register_new_user.email_placeholder')
                  "
                  type="email"
                  trim
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <b-card id="user-list">
      <b-table
        id="user-table"
        :fields="fields"
        :items="items"
        :busy="loading"
        show-empty
        hover
      >
        <template #empty>
          <div
            v-if="noDataFound(items, allUsersError)"
            class="alert alert-custom alert-notice alert-light-primary fade show mb-5"
            role="alert"
          >
            <div class="alert-text">
              {{ $t("administration.all_users.no_users_registered") }}
            </div>
          </div>
          <div
            v-if="hasError(allUsersError)"
            class="alert alert-custom alert-notice alert-light-danger fade show mb-5"
          >
            <div class="alert-text">
              {{ $t("administration.all_users.all_users_error_message") }}
            </div>
          </div>
        </template>

        <template #cell(actions)="data">
          <div>
            <div>
              <b-tooltip
                :target="
                  computeButtonId(VIEW_ASSIGNED_RIGHTS_ACTION_NAME, data.index)
                "
                triggers="hover"
              >
                {{ computeViewRightsTooltipMessage() }}
              </b-tooltip>
              <button
                :id="
                  computeButtonId(VIEW_ASSIGNED_RIGHTS_ACTION_NAME, data.index)
                "
                @click="
                  openViewRightsModal(
                    VIEW_ASSIGNED_RIGHTS_ACTION_NAME,
                    data.item,
                    data.index
                  )
                "
                class="btn btn-icon mr-2 action-button"
              >
                <i class="fas fa-eye action-icon" />
              </button>
              <b-modal
                :id="
                  computeModalId(VIEW_ASSIGNED_RIGHTS_ACTION_NAME, data.index)
                "
                :title="$t('administration.all_users.user_rights')"
              >
                <div v-for="right in viewRights" :key="right.id">
                  <div>{{ right.right_name }}</div>
                </div>
                <div
                  v-if="noDataFound(viewRights, getUserRightsError)"
                  class="alert alert-custom alert-notice alert-light-primary fade show mb-5"
                  role="alert"
                >
                  <div class="alert-text">
                    {{ $t("administration.all_users.no_rights_assigned") }}
                  </div>
                </div>
                <div
                  v-if="hasError(getUserRightsError)"
                  class="alert alert-custom alert-notice alert-light-danger fade show mb-5"
                >
                  <div class="alert-text">
                    {{
                      $t("administration.all_users.user_rights_error_message")
                    }}
                  </div>
                </div>
              </b-modal>

              <b-tooltip
                :target="computeButtonId(EDIT_RIGHTS_ACTION_NAME, data.index)"
                triggers="hover"
              >
                {{ computeEditRightsTooltipMessage() }}
              </b-tooltip>
              <button
                :id="computeButtonId(EDIT_RIGHTS_ACTION_NAME, data.index)"
                @click="
                  openEditRightsModal(
                    EDIT_RIGHTS_ACTION_NAME,
                    data.item,
                    data.index
                  )
                "
                class="btn btn-icon mr-2 action-button"
              >
                <i class="fas fa-edit action-icon"></i>
              </button>
              <b-modal
                :id="computeModalId(EDIT_RIGHTS_ACTION_NAME, data.index)"
                :title="$t('administration.edit_user_rights.modal_title')"
              >
                <div
                  v-if="
                    noDataFound(allRights, getAllPossibleRightsError) ||
                    hasError(getAllPossibleRightsError)
                  "
                >
                  <div
                    v-if="noDataFound(allRights, getAllPossibleRightsError)"
                    class="alert alert-custom alert-notice alert-light-primary fade show mb-5"
                    role="alert"
                  >
                    <div class="alert-text">
                      {{
                        $t("administration.edit_user_rights.no_rights_assigned")
                      }}
                    </div>
                  </div>
                  <div
                    v-if="hasError(getAllPossibleRightsError)"
                    class="alert alert-custom alert-notice alert-light-danger fade show mb-5"
                  >
                    <div class="alert-text">
                      {{ $t("administration.edit_user_rights.error_message") }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  <b-form-group
                    :label="$t('administration.edit_user_rights.rights_label')"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      v-model="selected"
                      :options="allRights"
                      :aria-describedby="ariaDescribedby"
                      name="user-rights"
                      class="rights-display"
                    ></b-form-checkbox-group>
                  </b-form-group>
                </div>
                <template #modal-footer>
                  <div class="w-100">
                    <b-button
                      @click="
                        saveRights(
                          data.item.username,
                          selected,
                          allRights,
                          data.index
                        )
                      "
                      :disabled="
                        noDataFound(allRights, getAllPossibleRightsError) ||
                        hasError(getAllPossibleRightsError)
                      "
                      size="sm"
                      class="float-right"
                      variant="primary"
                    >
                      {{ $t("administration.edit_user_rights.save_label") }}
                    </b-button>
                    <b-button
                      @click="closeModal(EDIT_RIGHTS_ACTION_NAME, data.index)"
                      size="sm"
                      class="float-right mr-6"
                    >
                      {{ $t("administration.edit_user_rights.close_label") }}
                    </b-button>
                  </div>
                </template>
              </b-modal>

              <b-tooltip
                :target="computeButtonId(REMOVE_USER_ACTION_NAME, data.index)"
                triggers="hover"
              >
                {{ computeDeleteTooltipMessage() }}
              </b-tooltip>
              <button
                :id="computeButtonId(REMOVE_USER_ACTION_NAME, data.index)"
                @click="
                  openRemoveUserModal(
                    REMOVE_USER_ACTION_NAME,
                    data.item,
                    data.index
                  )
                "
                class="btn btn-icon mr-2 action-button"
              >
                <i class="fas fa-trash-alt action-icon" />
              </button>
              <b-modal
                :id="computeModalId(REMOVE_USER_ACTION_NAME, data.index)"
                :title="$t('administration.remove_user.modal_title')"
                @show="closeModal(REMOVE_USER_ACTION_NAME, data.index)"
                @hidden="closeModal(REMOVE_USER_ACTION_NAME, data.index)"
                @ok="deleteUser(data.item)"
              >
                <div>
                  {{
                    $t("administration.remove_user.confirmation_message", [
                      data.item.username,
                    ])
                  }}
                </div>
              </b-modal>
            </div>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="rows > perPage"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="user-table"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "administration",
  data() {
    return {
      username: null,
      firstName: "",
      lastName: "",
      email: "",
      fields: [
        {
          key: "username",
          label: this.$t("administration.all_users.username_header"),
          tdClass: "font-size-base align-middle username-width",
        },
        {
          key: "first_name",
          label: this.$t("administration.all_users.firstname_header"),
          tdClass: "font-size-base align-middle first-name-width",
        },
        {
          key: "last_name",
          label: this.$t("administration.all_users.lastname_header"),
          tdClass: "font-size-base align-middle last-name-width",
        },
        {
          key: "email",
          label: this.$t("administration.all_users.email_header"),
          tdClass: "font-size-base align-middle email-width",
        },
        {
          key: "actions",
          label: this.$t("administration.all_users.actions_header"),
          tdClass: "font-size-base align-middle actions-width",
        },
      ],
      loading: true,
      items: [],
      viewRightsLoading: true,
      viewRights: [],
      selected: [],
      allRights: [],
      allRightsLoading: true,
      currentPage: 1,
      perPage: 10,
      allUsersError: null,
      getUserRightsError: null,
      getAllPossibleRightsError: null,
    };
  },
  components: {},
  created() {
    this.VIEW_ASSIGNED_RIGHTS_ACTION_NAME = "view-edit-rights-";
    this.EDIT_RIGHTS_ACTION_NAME = "edit-rights-";
    this.REMOVE_USER_ACTION_NAME = "remove-user-";
  },
  mounted() {
    this.getAllUsers();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users" }]);
  },
  computed: {
    rows() {
      return this.items.length;
    },
    usernameState() {
      if (this.username != null) {
        return this.username.length >= 4;
      }
      return null;
    },
    invalidUsername() {
      if (this.username != null) {
        if (this.username.length > 0) {
          return this.$t(
            "administration.register_new_user.username_validation_message_1"
          );
        }
        return this.$t(
          "administration.register_new_user.username_validation_message_2"
        );
      }
      return "";
    },
    firstNameState() {
      if (this.firstName != null) {
        return this.firstName.length >= 4;
      }
      return null;
    },
    invalidFirstName() {
      if (this.firstName != null) {
        if (this.firstName.length > 0) {
          return this.$t(
            "administration.register_new_user.firstname_validation_message_1"
          );
        }
        return this.$t(
          "administration.register_new_user.firstname_validation_message_2"
        );
      }
      return "";
    },
    lastNameState() {
      if (this.lastName != null) {
        return this.lastName.length >= 4;
      }
      return null;
    },
    invalidLastName() {
      if (this.lastName != null) {
        if (this.lastName.length > 0) {
          return this.$t(
            "administration.register_new_user.lastname_validation_message_1"
          );
        }
        return this.$t(
          "administration.register_new_user.lastname_validation_message_2"
        );
      }
      return "";
    },
    emailState() {
      if (this.email != null) {
        return (
          this.email.length >= 4 &&
          this.email.includes("@") &&
          this.email.includes(".")
        );
      }
      return null;
    },
    invalidEmail() {
      if (this.email != null) {
        if (
          this.email.length > 0 &&
          !this.email.includes("@") &&
          !this.email.includes(".")
        ) {
          return this.$t(
            "administration.register_new_user.email_validation_message_1"
          );
        }
        if (!this.email.includes("@")) {
          return this.$t(
            "administration.register_new_user.email_validation_message_1"
          );
        }
        return this.$t(
          "administration.register_new_user.email_validation_message_2"
        );
      }
      return "";
    },
  },
  methods: {
    getAllUsers() {
      let self = this;
      self.items = [];
      self.loading = true;
      self.allUsersError = null;

      ApiService.post("/allusers/")
        .then((response) => {
          self.items = response.data;
          self.loading = false;
        })
        .catch(({ response }) => {
          self.allUsersError = response.data;
        });
    },
    noDataFound(items, error) {
      return (items == null || items.length == 0) && error == null;
    },
    hasError(error) {
      return error != null;
    },
    registerUser() {
      let self = this;

      let registerUserReqObj = {
        username: this.username,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
      };

      ApiService.post("/register/", registerUserReqObj)
        .then(() => {
          self.getAllUsers();
          self.$toastr.s(
            self.$t("administration.register_new_user.success_message")
          );
        })
        .catch(() => {
          self.$toastr.e(
            self.$t("administration.register_new_user.error_message")
          );
        });
    },
    async getUserRights(username) {
      let self = this;
      self.viewRights = [];
      self.getUserRightsError = null;
      return ApiService.post("/user-rights/", { username: username });
    },
    async getUserPossibleRights(username) {
      this.allRights = [];
      return ApiService.post("/user-rights/", { username: username });
    },
    saveRights(username, selectedRights, allRights, index) {
      let rightsToBeAdded = allRights.filter((right) =>
        selectedRights.includes(right.value)
      );
      let rightsToBeRemoved = allRights.filter(
        (right) => !selectedRights.includes(right.value)
      );

      let requestBody = {
        username: username,
        rightsToBeAdded: rightsToBeAdded,
        rightsToBeRemoved: rightsToBeRemoved,
      };

      const self = this;
      ApiService.post("/edit-user-rights/", requestBody)
        .then(() => {
          self.selected = [];
          requestBody = {};
          self.$toastr.s(
            self.$t("administration.edit_user_rights.success_message")
          );
        })
        .catch(() => {
          self.$toastr.e(
            self.$t("administration.edit_user_rights.error_message_1")
          );
        })
        .finally(() => {
          self.closeModal("edit-rights-", index);
        });
    },
    deleteUser(item) {
      const self = this;
      const requestBody = { username: item.username };
      ApiService.post("/delete-user/", requestBody)
        .then(() => {
          self.getAllUsers();
          self.$toastr.s(self.$t("administration.remove_user.success_message"));
        })
        .catch(() => {
          self.$toastr.e(self.$t("administration.remove_user.error_message"));
        });
    },
    resetModal() {
      this.username = null;
      this.firstName = null;
      this.lastName = null;
      this.email = null;
    },
    computeModalId(actionName, id) {
      return actionName + id;
    },
    openViewRightsModal(actionName, item, id) {
      let self = this;
      this.getUserRights(item.username)
        .then((response) => {
          response.data.forEach((rightObj) => {
            if (rightObj["has_right"]) {
              self.viewRights.push(rightObj);
            }
          });
          self.openModal(actionName, id);
        })
        .catch(({ response }) => {
          self.getUserRightsError = response.data;
          self.openModal(actionName, id);
        });;
    },
    openEditRightsModal(actionName, item, id) {
      let self = this;
      this.getUserPossibleRights(item.username).then((response) => {
          response.data.forEach((rightObj) => {
            if (rightObj["right_name"].indexOf("Can ") != -1) {
              let optionObj = {
                value: rightObj["right_id"],
                text: rightObj["right_name"],
                codeName: rightObj["code_name"],
              };
              self.allRights.push(optionObj);
              if (rightObj["has_right"]) {
                self.selected.push(optionObj["value"]);
              }
              self.openModal(actionName, id);
            }
          });
        })
        .catch((response) => {
          self.getAllPossibleRightsError = response.data;
          self.openModal(actionName, id);
        });;
    },
    openRemoveUserModal(actionName, item, id) {
      this.openModal(actionName, id);
    },
    openModal(actionName, id) {
      this.$bvModal.show(actionName + id);
    },
    closeModal(actionName, id) {
      this.$bvModal.hide(actionName + id);
    },
    computeButtonId(actionName, id) {
      return actionName + id;
    },
    computeViewRightsTooltipMessage() {
      return this.$t(
        "administration.all_users.view_assigned_rights_action_tooltip"
      );
    },
    computeEditRightsTooltipMessage() {
      return this.$t("administration.all_users.edit_rights_action_tooltip");
    },
    computeDeleteTooltipMessage() {
      return this.$t("administration.all_users.remove_rights_action_tooltip");
    },
  },
};
</script>
<style lang="scss">
.create-button {
  text-align: end;
}
.rights-display {
  display: grid !important;
}
.action-icon {
  color: #3699ff;
  font-size: 14px !important;
}
.username-width {
  width: 15% !important;
}
.first-name-width {
  width: 20% !important;
}
.last-name-width {
  width: 20% !important;
}
.email-width {
  width: 30% !important;
}
.actions-width {
  width: 15% !important;
}
</style>
